/* @flow */

module.exports = {
  "LOCALE": {
    "ISO": {
      "639-1": "sv",
      "3166-1": "SE",
      "IETF": "sv-SE",
    },
    "CURRENCY": "SEK",
    "CURRENCY_SHORT": "kr",
  },
  "META": {
    "TITLE": "E.ON Webbshop",
    "NAME": "E.ON Webbshop",
    "DESCRIPTION": "E.ON Webbshop",
    "GOOGLE_SITE_OWNER": "",
    "HELPDESK_LINK": "https://helpdesk.crossroads.se/?s=8b1b7f7e289a69a42e872427689875e4",
  }, "LOGO": {
    "ALT": "E.ON",
    "TITLE": "Gå till startsidan",
  },
  "CURRENCY_SHORT": {
    "NOK": "kr",
    "DKK": "kr",
    "SEK": "kr",
    "EUR": "€",
  },
  "PRICE": {
    "SHOP_PRICE": "Shoppris",
    "MSRP": "Ord. pris",
    "FROM": "från",
  },
  "HAMBURGER": {
    "TITLE": "Meny",
  },
  "HOMEVIEW": {
    "INTRO": "I vår webbshop hittar du som E.ON-kund produkter som gör ditt hem smartare, härligare och mer hållbart - till exklusivt förmånliga priser. Flera av dem hjälper dig dessutom att minska energikostnaderna. Alla frakter klimatkompenseras och är gratis vid beställningar över 500 kr.",
    "INTRO_2": "Välkommen till ditt supersmarta hem",
    "WHOLE_CATEGORY": "Se hela kategorin",
  },
  "ALL_PRODUCTS": {
    "TITLE": "Alla Produkter",
    "HERO": {
      "TITLE_PREFIX_LOADING": "Laddar produkter från",
      "PRODUCTS_FROM": "Produkter från",
      "TITLE_PREFIX_EMPTY": "Inga produkter att visa från",
      "TITLE_PREFIX_SINGULAR": "Visar 1 produkt från",
      "TITLE_PREFIX_PLURAL": "Visar %totalCount produkter från",
      "IN_PRICERANGE": "i prisintervallet",
    },
  },
  "ADVENT_CALENDAR": {
    "TEXT": "Julkalender",
    "LINK": "/julkalender",
    "DOOR_TITLE": "Lucka %day",
    "VIEW_ALL_PRODUCTS": "Visa alla produkter i dagens lucka",
    "BACK": "Tillbaka till julkalendern",
    "SAVE_PERCENTAGE": "Spara upp till %percentage%",
    "DOOR_OPEN_X_DAYS": "Varje lucka är öppen i %days dagar",
    "CATEGORY_URL": "julkalender",
    "OPEN_DOOR": "Öppna lucka",
    "OPEN_DOOR_ALT": "Öppna lucka",
    "CLOSED_DOOR_ALT": "Stängd lucka",
    "CALENDAR_CLOSED": "Julkalendern är stängd",
    "TOTAL_COUNT_EMPTY": "Inga produkter att visa",
    "TOTAL_COUNT_SINGULAR": "Visar 1 produkt",
    "TOTAL_COUNT_PLURAL": "Visar %totalCount produkter",
    "LANDING": {
      "HEADING": "E.ON Webbshop JULKALENDER",
      "TEXT": "Spännande erbjudanden varje dag fram till julafton, startar första december.",
    },
    "FORM": {
      "SUBSCRIBE_NOW": "Missa inte årets julkalender. Anmäl dig nu och få fina erbjudanden och energibesparingstips varje dag fram till jul!",
      "EMAIL": "E-mail",
      "SUBMIT": "Bekräfta anmälan",
    },
  },
  "ORDER_HISTORY": {
    "PAGINATION": "Sida %current / %total",
  },
  "SORT": {
    "OFFCANVAS": {
      "TITLE": "Sortera",
      "SHOW_RESULT": "Visa resultat",
      "CLOSE_MENU": "Stäng sortering",
    },
  },
  "FILTER": {
    "FILTERBAR": {
      "ALL_FILTERS": "Filtrera",
      "FILTER_BY_POINTS": "Filtrera på pris",
      "FILTER_BY_PRICE": "Filtrera på pris",
      "PRICE_RANGE": "%min - %max pris",
      "SORT": "Sortera",
      "SORT_DEFAULT": "Utvalda",
      "SORT_PRICE_DESC": "Pris ↓",
      "SORT_PRICE_ASC": "Pris ↑",
      "SORT_NAME_DESC": "Namn ↓",
      "SORT_NAME_ASC": "Namn ↑",
    },
    "OFFCANVAS": {
      "TITLE": "Filter",
      "SHOW_RESULT_TOTAL_SINGLE": "%total Produkt",
      "SHOW_RESULT_TOTAL_MULTIPLE": "%total Produkter",
      "SHOW_RESULT": "Visa resultat",
      "CLOSE_MENU": "Stäng filter",
    },
    "ACTIVE_FILTERS": {
      "CLEAR_ALL": "Rensa filter",
      "NO_ACTIVE_FILTERS": "Du har inga aktiva filter",
      "PRICE_RANGE": "Pris (%range)",
      "TOTAL_COUNT": "Visar %count produkter",
    },
    "FILTERMENU": {
      "FILTER_BY_PRICE": "Filtrera på pris",
      "FILTER_BY_POINTS": "Filtrera på pris",
      "RESET_PRICE": "Rensa filter",
      "PRICE_FROM": "Från",
      "PRICE_TO": "Till",
      "BALANCE_FILTER": "Filtrera på saldo (%value pris)",
      "WITHIN_BALANCE": "Produkter inom poängsaldo",
    },
  },
  "WISHLIST": {
    "TITLE": "Önskelista",
    "REMOVE_FROM_WISHLIST": "Ta bort från önskelista",
    "EMPTY": "Du har inga produkter i din önskelista.",
    "EXPLORE_ASSORTMENT": "Utforska sortimentet",
  },
  "ADDRESS": {
    "FIRSTNAME": "Förnamn",
    "LASTNAME": "Efternamn",
    "STREET_1": "Gatuadress 1",
    "STREET_2": "Gatuadress 2 (frivilligt)",
    "POSTCODE": "Postnummer",
    "CITY": "Stad",
    "COUNTRY": "Land",
    "EMAIL": "E-mail",
    "TELEPHONE": "Telefon",
    "EDIT": "Ändra",
    "NOT_YOUR_COUNTRY": "Inte ditt land?",
  },
  "OR": "eller",
  "CART": {
    "MINI_HEADER": "Varukorg",
    "MINI_SHOW_LESS": "Visa mindre",
    "MINI_SHOW_MORE": "Se översikt",
    "EMPTY": "Varukorgen är tom",
    "OF": "av",
    "CTA": "Gå till kassan",
    "EDIT": "Ändra i varukorg",
    "INCREMENT": "Öka kvantitet",
    "DECREMENT": "Minska kvantitet",
    "QUANTITY": "Kvantitet",
    "CONTINUE_SHOPPING": "fortsätt handla",
    "SUBTOTAL": "Totalt",
    "TO_CHECKOUT": "Gå till kassan",
    "REMOVE_PRODUCT_LONG": "Ta bort produkt",
    "REMOVE_PRODUCT_SHORT": "Ta bort",
    "REMOVE_APPROVE": "Ja, ta bort",
    "CONFIRM_TEXT": "Vill du verkligen ta bort '%itemName' från varukorgen?",
    "QTY": "st",
    "FREE_SHIPPING_AT": "%left kvar till fri frakt!",
    "FREE_SHIPPING": "Fri frakt!",
    "SUBSCRIBE_TO_NEWSLETTER": "Jag vill ta del av erbjudanden från E.ON Webshop",
  },
  "SUMMARY": {
    "GRANDTOTAL": "Totalt",
    "MINIMIZE_SUMMARY": "Minimera översikt",
    "SHIPPING": "Frakt",
    "SUBTOTAL": "Ordervärde",
    "TITLE": "Summering",
    "TO_PAY": "Att betala",
    "VIEW_DETAILS": "Visa detaljer",
    "DISCOUNT": "Rabatt",
  },
  "CONFIRM_DIALOG": {
    "CANCEL": "Avbryt",
  },
  "CHECKOUT": {
    "TITLE": "Kassa",
    "NEED_HELP": "Behöver du hjälp?",
    "CONTACT_CUSTOMER_SERVICE": "Kontakta kundtjänst",
    "TO_PAYMENT": "Gå till betalning",
    "CHOOSE_PAYMENT_METHOD": "Vidare till betalning",
    "AMOUNT_TO_PAY": "Att betala",
    "CASH": "Kortbetalning",
    "PAY_WITH_CARD": "Betala med kort",
    "CARD_PAYMENT": "Betala med kort",
    "EDIT": "Ändra",
    "PAYMENT_AND_DELIVERY": "Betalning & leverans",
    "SENT_TO": "Skickas till",
    "CONFIRM_ORDER": "Lägg beställning",
    "CONTINUE_SHOPPING": "Fortsätt handla",
    "BACK_TO_CART": "Gå till varukorg",
    "PAYMENT_TITLE": "Hur vill du betala?",
    "I_ACCEPT_THE": "Jag accepterar",
    "TERMS": "köpvillkoren",
    "ACCORDION_HEADER_CART": "Varukorg",
    "DISCOUNT_CODE": {
      "HEADER": "Rabattkod",
      "CHECKBOX_LABEL": "Jag har en rabattkod",
      "APPLY": "Applicera",
      "REMOVE": "Ta bort",
    },
    "ADDRESS": {
      "ADDRESS": "Adress",
      "SHIPPING": "Leveransadress",
      "BILLING": "Kvittoadress",
      "SHIP_TO_SAME_ADDRESS": "Leverera till samma adress",
      "EMPTY_ADDRESS": "Adressen är inte komplett, vänligen uppdatera",
    },
    "STEPS": {
      "CART": {
        "SHORT": "Varukorg",
        "LONG": "Varukorg",
      },
      "OVERVIEW": {
        "SHORT": "Betala",
        "LONG": "Betala",
      },
    },
  },
  "PAYMENT": {
    "USE_CARD": "Betala med kort",
    "USE_SAVED_CARD": "Betala med sparat kort",
  },
  "PAYMENT_METHODS": {
    "APPLE_PAY": "pay",
  },
  "PAYMENT_LIMIT": {
    "TEXT_ONE": "Det är för tillfället inte möjligt att handla presentkort för mer än %amount.",
    "TEXT_TWO": "Vänligen kontakta kundtjänst för mer information på <a href=\"mailto:info@awardit.com\">info@awardit.com</a>.",
  },
  "CUSTOMER_SERVICE": {
    "TEXT": "Har du en fråga?",
    "LINK": "/faq",
    "ALT_TEXT": "FAQ",
  },
  "SUCCESS": {
    "TITLE": "Tack för ditt köp!",
    "HEADING": "Tack för ditt köp!",
    "ORDER_ID": "Ditt ordernummer är:",
    "CONFIRMATION": "Din beställning är nu registrerad och kommer skickas till dig så snart som möjligt! Vi har skickat en beställningsbekräftelse till %email. Bekräftelsen fungerar som kvitto för ditt köp och innehåller även kontaktinformation till vår kundservice.",
    "DELIVERY": "Så snart din beställning skickats får du även en leveransbekräftelse per e‑post. Denna innehåller spårningsinformation så att du kan följa paketets väg – hela vägen från vårt lager till dig.",
    "SHOW_TICKET": "Visa kvitto",
    "LOADING": "Läser in order…",
  },
  "SERIAL_CODE": {
    "FETCH_STATUS": {
      "LOADING": "Vänta medan vi laddar din värdekod",
      "ERROR": "An error occured. Your code is sent to your e-mail",
    },
  },
  "OLD_BROWSER": {
    "INFO": "Hej! Du använder en utdaterad webbläsare. Vänligen <a href=\"http://outdatedbrowser.com\" rel=\"noopener\" target=\"blank\">uppdatera din webbläsare</a> för att använda shop.eon.se",
  },
  "CATEGORY": {
    "PRODUCTS_IN_CATEGORY_TITLE_SINGULAR": "Visar 1 produkt",
    "PRODUCTS_IN_CATEGORY_TITLE_PLURAL": "Visar %itemsCount produkter",
    "PRODUCTS_IN_CATEGORY_TITLE_EMPTY": "Laddar produkter...",
  },
  "PRODUCTLIST": {
    "EMPTY": "Det finns inga produkter att visa just nu",
  },
  "POPULAR": {
    "POPULAR_PRODUCTS": "Populära produkter",
  },
  "404": {
    "TITLE": "Sidan kunde inte hittas",
    "HEADING": "404",
    "TEXT": "Oops, den här sidan finns inte.",
    "SUBHEADING": "Sidan kunde inte hittas",
  },
  "SERVER_ERROR": {
    "TITLE": "Serverfel",
    "HEADING": "Serverfel: 500",
    "SUBHEADING": "Vänligen återkom inom kort!",
    "TEXT": "<strong>Behöver du kontakta oss?</strong><br/> Skicka ett mail till <a href=\"mailto:kundservice@crossroads.se\">kundservice@crossroads.se</a>",
  },
  "SEARCH": {
    "TITLE_PREFIX": "Sök: ",
    "PLACEHOLDER": "Sök efter produkter",
    "LABEL": "Tryck enter för att söka",
    "RESULTS": "Resultat för sökningen \"%query\"",
    "TYPE": {
      "CATEGORY": "Kategori",
      "PRODUCT": "Produkt",
      "BRAND": "Varumärke",
    },
  },
  "HEADER": {
    "SHOPNAME": "E.ON Webbshop Shop",
    "NAVIGATION": "Navigation",
    "SEARCH": "Sök",
    "CATEGORIES": "Kategorier",
    "CATEGORIES_TOGGLE": "Fler kategorier",
    "CART": "Kassan",
    "LOGOUT": "Logga ut",
    "LOGIN": "Logga in",
    "DELIVER_TO": "Levereras till",
  },
  "FOOTER": {
    "LINKS": {
      "TERMS": {
        "TEXT": "Villkor",
        "LINK": "/villkor",
      },
      "PRIVACY_POLICY": {
        "TEXT": "Integritetspolicy",
        "LINK": "/privacy-policy",
      },
    },
    "NEED_HELP": {
      "COOKIES": "Hantera cookies",
    },
    "COPYRIGHT": "E.ON webbshop drivs av <a href='https://www.awardit.com'>Awardit CLS AB</a>. Copyright © %year Awardit CLS AB. All Rights Reserved.",
  },
  "BREADCRUMBS": {
    "HOME": "Hem",
    "HOME_URL": "/",
    "SEARCH": "Sökresultat för \"%query\"",
    "ADVENT_CALENDAR": "Julkalender",
  },
  "PRODUCT": {
    "ADD_TO_CART": "Köp nu",
    "OUT_OF_STOCK": "Ej i lager",
    "OTHERS_ALSO_LIKED": "Andra gillade också",
    "OPTION_DROPDOWN_PLACEHOLDER": "Välj ett val",
    "LOGIN_TO_PURCHASE": "Logga in för att kunna handla",
    "CHANGE_IMAGE": "Byt bild",
    "GO_TO_MANUFACTURER": "Se alla produkter från %brand",
    "ABOUT_BRAND": "Om %brand",
    "COLOR": "Färg:",
    "CHOOSE_A_COLOR": "Välj en färg",
    "DESCRIPTION": "Produktbeskrivning",
    "EARNINGS": "Earnings from this purchase",
    "SHIPPING_TERMS": "Shipping terms",
    "EXTERNAL_SHOWCASE_DEFAULT": "Till produkten",
  },
  "VALIDATION": {
    "REQUIRED": "Vänligen fyll i detta fält",
    "EMAIL": "Vänligen fyll i en korrekt e‑postadress.",
    "errorInvalidEmail": "Vänligen fyll i en korrekt e‑postadress.",
    "errorEmailExists": "Emailadressen du angav används redan av ett annat konto.",
    "PHONE": "Vänligen fyll i ett korrekt mobiltelefonnummer.",
    "NUMERIC": "Fältet får bara innehålla nummer",
    "LENGTH_LT": "Fältet innehåller för många tecken",
    "POSTCODE": "Vänligen fyll i ett korrekt postnummer. (XXX XX)",
    "NO_SHIPPING_GB": "Ej tillgängligt på grund av Brexit.",
  },
  "COUPON_LIGHTBOX": {
    "TITLE": "Välkommen",
    "INFO": "Vi har lagt till din värdekod automatiskt, så att du slipper fylla i den manuellt i kassan. Fiffigt, va?",
    "CTA": "Börja handla",
  },
  "COOKIE_CONSENT": {
    "TEXT": "För att förbättra och anpassa din upplevelse av våra digitala tjänster använder E.ON cookies. Läs vår <a href=\"/privacy-policy\">Integritetspolicy</a> om du vill veta mer om cookies.",
    "ALL": "Tillåt alla",
    "NECESSARY": "Tillåt nödvändiga",
    "SELECTED": "Tillåt urval",
  },
  "COOKIE_OPTIONS": {
    "NECESSARY": "Nödvändiga",
    "STATISTICAL": "Statistiska",
    "MARKETING": "Marknadsföring",
  },
  "STATUS_CODE": {
    /* Client */
    "UNKNOWN": "Ett okänt fel har uppstått. Vänligen ladda om sidan och försök igen.",
    "NETWORK_ERROR": "Anropet avbröts. Vänligen verifiera att du har en aktiv internetanslutning eller försök igen om en stund.",
    "PLACE_ORDER_CATCH": "Ett fel uppstod. Vänligen försök igen senare.",

    "DISCOUNT_CODE_APPLIED": "Rabattkod applicerad.",
    "DISCOUNT_CODE_FAILURE": "Rabattkoden kunde inte appliceras, vänligt försök igen eller kontakta kundtjänst.",
    "DISCOUNT_CODE_REMOVE_FAILURE": "Rabattkoden kunde inte tas bort, vänligt försök igen eller kontakta kundtjänst.",

    "errorUnknownProduct": "Okänd produkt, vänligen försök igen.",

    "errorNoPaymentMethod": "Ingen betalmetod vald.",
    "errorProductQuantityNotAvailable": "Det antal du angav finns inte tillgängligt just nu.",
    "errorProductNotInStock": "Produkten du försökte lägga i varukorgen finns dessvärre inte i lager just nu.",
    "errorProductMaxQuantity": "Det angivna antalet finns dessvärre inte i lager just nu.",
    "errorProductNotChosenVariant": "Vänligen välj %attributeLabel för att kunna lägga till produkten i din varukorg.",
    "termsNotAccepted": "Du måste godkänna köpvillkoren för att genomföra ditt köp.",

    "errorNotLoggedIn": "Du är inte inloggad, vänligen logga in.",
    "errorInvalidState": "Något gick fel, vänligen prova igen.",
    "errorInvalidLogin": "Felaktiga inloggningsuppgifter",
    "errorMissingCustomer": "Kunden du försöker logga in som finns inte",
    "errorBlacklistedEmail": "Emailadressen du angav används redan av ett annat konto. Vänligen ange en annan.",
    "errorEmailExists": "Emailadressen du angav används redan av ett annat konto. Vänligen ange en annan.",
    "errorInvalidEmailOrPassword": "Fel epostadress eller lösenord",

    "errorInvalidEmail": "Ogiltig Emailadress",
    "errorInvalidEmailAddress": "Ogiltig Emailadress",

    "ERROR_UPDATING_ADDRESS": "Fel vid sparande av adressen, vänligen kontrollera uppgifterna och försök igen.",

    "SUCCESSFULLY_LOGGED_IN": "Du är nu inloggad",
    "LOGIN_ERROR": "Inloggningen misslyckades. Vänligen försök igen eller vänta och testa igen om en stund.",

    "YOUR_CARD_HAS_INSUFFICIENT_FUNDS": "Du saknar täckning på kortet.",
    "YOUR_CARDS_SECURITY_CODE_IS_INCORRECT": "CVC koden för kortet är inte korrekt.",
    "YOUR_CARD_WAS_DECLINED": "Kortet nekades.",
    "YOUR_CARD_HAS_EXPIRED": "Kortet är utgånget.",
    "AN_ERROR_OCCURRED_WHILE_PROCESSING_YOUR_CARD_TRY_AGAIN_IN_A_LITTLE_BIT": "Ett fel inträffade, försök igen om en liten stund.",
    "WE_ARE_UNABLE_TO_AUTHENTICATE_YOUR_PAYMENT_METHOD_PLEASE_CHOOSE_A_DIFFERENT_PAYMENT_METHOD_AND_TRY_AGAIN": "Vi kunde inte godkänna betalningsmetoden. Vänligen försök igen.",
    "INTERNAL_SERVER_ERROR": "Internt serverfel.",

    "CODE_COPIED": "Värdekoden kopierades till urklippet.",
    "CODE_NOT_COPIED": "Värdekoden kunde inte kopieras.",
    "WISHLIST_VARIANT": "Välj en konfiguration först",
    "errorMaxAttemptsReached": "För många anrop. Vänligen vänta några minuter och prova igen.",
    "invalid_sso_token_or_otp": "Felaktig OTP. Vänligen försök igen",
    "invalid_sso_ticket": "Din session löpte ut. Vänligen logga in igen.",
    "errorNoTicket": "Din session löpte ut. Vänligen logga in igen.",
    "NEW_OTP_SMS_SENT": "Ny kod skickades",
    "ITEM_REMOVED_FROM_CART": "%name togs bort från varukorgen eftersom att den inte längre finns i lager.",
    "NO_SHIPPING_GB": "På grund av Brexit och de nya reglerna kan vi för närvarande inte leverera till Storbritannien. Vi jobbar heltid med detta och hoppas på en lösning snart.",

    "ADVENT_CALENDAR": {
      "notModified": "E-postadressen är redan registrerad",
      "invalidEmail": "Ogiltig E-postadress",
      "success": "Du är nu registrerad",
    },
  },
};
